import { render, staticRenderFns } from "./UpdateLabelModal.vue?vue&type=template&id=d2d58116&scoped=true&"
import script from "./UpdateLabelModal.vue?vue&type=script&lang=js&"
export * from "./UpdateLabelModal.vue?vue&type=script&lang=js&"
import style0 from "./UpdateLabelModal.vue?vue&type=style&index=0&id=d2d58116&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2d58116",
  null
  
)

export default component.exports