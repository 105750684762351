<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <validation-observer v-slot="{ passes }">
    <form
      ref="vldParent"
      class="vld-parent"
      @submit.prevent="passes(saveChanges)"
    >
      <div class="preferences-page__form-control">
        <label
          class="text_m"
          for="firstName"
        >
          Full Name
          <span class="preferences-page__form-required">*</span>
        </label>
        <div
          class="
            preferences-page__form-group
            preferences-page__form-group_inline
          "
        >
          <default-input
            :value.sync="formData.first_name"
            name="First Name"
            input-id="firstName"
            validation-string="required|alpha_spaces"
            size="s"
            :is-fulled="true"
          />
          <default-input
            :value.sync="formData.last_name"
            name="Last Name"
            validation-string="required|alpha_spaces"
            size="s"
            :is-fulled="true"
          />
        </div>
      </div>

      <div class="preferences-page__form-control">
        <label
          class="text_m"
        >
          Email
          <span class="preferences-page__form-required">*</span>
        </label>
        <default-input
          :value.sync="formData.email"
          name="Email"
          validation-string="required|email"
          class="preferences-page__form-input"
          size="s"
          :is-disabled="true"
        />
      </div>

      <div class="preferences-page__form-control">
        <label
          class="text_m"
          for="phone"
        >
          Phone
          <span class="preferences-page__form-required">*</span>
        </label>
        <default-input
          input-id="phone"
          :value.sync="formData.phone"
          name="Phone"
          validation-string="required|numeric"
          class="preferences-page__form-input"
          size="s"
        />
      </div>

      <div class="preferences-page__form-control">
        <label
          class="text_m"
          for="companyName"
        >
          Company name
          <span class="preferences-page__form-required">*</span>
        </label>
        <default-input
          input-id="companyName"
          :value.sync="formData.company_name"
          name="Company name"
          validation-string="required"
          class="preferences-page__form-input"
          size="s"
        />
      </div>

      <div class="preferences-page__form-control">
        <label class="text_m">Industry
          <span class="preferences-page__form-required">*</span>
        </label>
        <default-select
          :items="industries"
          :value="user.industry"
          data-closable="industries"
          class="preferences-page__form-input"
          validation-string="required"
          placeholder="Company Industry"
          name="Company Industry"
          @select="updateIndustry"
        />
      </div>

      <div class="preferences-page__form-control">
        <label class="text_m">Location
          <span class="preferences-page__form-required">*</span>
        </label>
        <div class="preferences-page__form-group">
          <default-select
            :items="countries"
            data-closable="countries"
            :value="userCountry"
            validation-string="required"
            class="mb-2"
            placeholder="Country"
            name="Country"
            @select="updateCountry"
          />
          <default-input
            :value.sync="formData.state"
            class="mb-2"
            validation-string="alpha"
            size="s"
            placeholder="State"
          />
          <default-input
            :value.sync="formData.city"
            validation-string="alpha"
            size="s"
            placeholder="City"
          />
        </div>
      </div>

      <div class="preferences-page__form-control">
        <label
          class="text_m"
          for="address1"
        >Address Line 1
        </label>
        <default-input
          input-id="address1"
          :value.sync="formData.address_1"
          class="preferences-page__form-input"
          placeholder="Your address"
          size="s"
        />
      </div>

      <div class="preferences-page__form-control">
        <label
          class="text_m"
          for="address2"
        >Address Line 2
        </label>
        <default-input
          input-id="address2"
          :value.sync="formData.address_2"
          class="preferences-page__form-input"
          placeholder="Your address"
          size="s"
        />
      </div>

      <div class="preferences-page__form-control">
        <label
          class="text_m"
          for="zipCode"
        >ZIP Code
        </label>
        <default-input
          input-id="zipCode"
          name="zipCode"
          :value.sync="formData.zip_code"
          validation-string="alpha_dash"
          class="preferences-page__form-input"
          placeholder="Your ZIP Code"
          size="s"
        />
      </div>

      <default-button
        class="margin-left"
        type="submit"
        title="Save changes"
        color="primary"
        form="rectangle"
        size="s"
      />
    </form>
  </validation-observer>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { updateUser } from '@/api/userMethods';
  import { ValidationObserver } from 'vee-validate';
  import industries from '@/data/industries.js';
  import countries from '@/data/countries.json';

  const convertArray = array => {
    return array.map(item => {
      const obj = {};
      obj.name = item;
      return obj;
    });
  };

  export default {
    components: {
      DefaultInput,
      DefaultButton,
      DefaultSelect,
      ValidationObserver,
    },
    mixins: [loaderMixin],

    data () {
      return {
        formData: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          company_name: '',
          industry: '',
          country_code: '',
          state: '',
          city: '',
          address_1: '',
          address_2: '',
          zip_code: '',
          is_deduplicate_profiles: false,
        },
        industries: convertArray(industries),
        countries,
        userCountry: '',
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
      }),
    },
    created () {
      const code = this.$store.getters['user/user'].country_code;
      this.userCountry = this.countries.find(c => c.code === code)?.name || '';
    },
    mounted () {
      this.fillFormData();
    },
    methods: {
      fillFormData () {
        Object.keys(this.formData).forEach(key => {
          if (this.user[key]) {
            this.formData[key] = this.user[key];
          }
        });
      },
      updateIndustry (item) {
        this.formData.industry = item ? item.name : '';
      },
      updateCountry (item) {
        this.formData.country_code = item ? item.code : '';
      },
      async saveChanges () {
        this.loaded = false;
        try {
          this.formData = Object.entries(this.formData).reduce((prev, [k, v]) => {
            if (typeof v === 'string') {
              prev[k] = v.trim();
            }
            return prev;
          }, {});
          const user = await updateUser(this.formData);
          this.$store.dispatch('user/SET_USER', user);
          this.$noty.success('Basic information has been successfully edited');
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
