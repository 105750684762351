/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getAllCredentials (space = '') {
  const data = await useHttp(
    '/credentials/fetch',
    'GET',
    null,
    { space }
  );

  return data.payload;
}

async function deleteCredential (credentialId) {
  const data = await useHttp(
    `/credentials/${credentialId}/delete`,
    'POST'
  );

  return data.payload;
}

async function addCredential (credentials) {
  const data = await useHttp(
    '/credentials/create',
    'POST',
    // { email, password, country_code, timezone_offset,  work_hours_start, work_hours_end}
    credentials,
    null,
    true
  );
  return data.payload;
}

async function updateCredential (credential, credentialId) {
  const data = await useHttp(
      `/credentials/${credentialId}/update`,
      'POST',
      credential,
      null,
      true
  );
  return data.payload;
}

async function syncDialogs (credentialId) {
  const data = await useHttp(
      `/credentials/${credentialId}/sync_dialogs`,
      'POST',
      null
  );
  return data.payload;
}

async function getCredential (credentialId) {
  const data = await useHttp(
      `/credentials/${credentialId}/fetch`,
      'GET',
      null
  );
  return data.payload;
}

async function sendCode (credentialId, resend, code = null) {
  const data = await useHttp(
      `/credentials/${credentialId}/send`,
      'POST',
      {
        resend,
        code,
      },
      null,
      true
  );
  return data.payload;
}

async function duplicateWorkflows (credentials, workflows) {
  const data = await useHttp(
    '/credentials/duplicate_workflows',
    'POST',
    {
      update_dict: JSON.stringify({
        credentials,
        workflows,
      }),
    }
  );
  return data.payload;
}

async function updateProxy (id, proxy) {
  const data = await useHttp(
    `/credentials/${id}/proxy`,
    'POST',
    proxy,
    null,
    true
  );
  return data.payload;
}

async function checkSales (id) {
  const data = await useHttp(
    `/credentials/${id}/check_sales`,
    'POST',
    null,
    null
  );
  return data.payload;
}

async function doAuth (id) {
  const data = await useHttp(
    `/credentials/${id}/do_auth`,
    'POST',
    null,
    null
  );
  return data.payload;
}

async function setTwoFactor (id, username, password) {
  const data = await useHttp(
    `/credentials/${id}/two_factor`,
    'PUT',
    {
      username,
      password,
    },
    null,
    true
  );
  return data.payload;
}

async function getCredentialIntegrations (credentialsId) {
  const data = await useHttp(
    `/credentials/${credentialsId}/integrations`
  );
  return data.payload;
}
/**
 * @param {string} credentialsId
 * @returns {string[]} blacklist
 */
async function getBlacklist (credentialsId) {
  const data = await useHttp(
    `/credentials/${credentialsId}/blacklist`,
    'GET'
  );
  return data.payload;
}

/**
 * @param {string} credentialsId
 * @param {string[]} values
 * @returns {string[]} new blacklist
 */
async function updateBlacklist (credentialsId, values) {
  const data = await useHttp(
    `/credentials/${credentialsId}/blacklist`,
    'PUT',
    { values },
    null,
    true
  );
  return data.payload;
}

async function refreshProxy (credentialId) {
  const data = await useHttp(
    `/credentials/${credentialId}/refresh_proxy`,
    'POST',
    null,
    null,
    true
  );
  return data.payload;
}

export {
  getBlacklist,
  updateBlacklist,
  updateProxy,
  doAuth,
  setTwoFactor,
  checkSales,
  getAllCredentials,
  deleteCredential,
  addCredential,
  updateCredential,
  getCredential,
  syncDialogs,
  duplicateWorkflows,
  sendCode,
  getCredentialIntegrations,
  refreshProxy
};
