/**
  * @typedef {Object} NotificationOptions
  * @prop {string} [title]
  * @prop {string} text
  * @prop {boolean} [hasClosedButton]
  * @prop {boolean} [withoutDuration]
  * @prop {ActionButton} [actionButton]
*/
/**
  * @typedef {Object} ActionButton
  * @prop {string} title
  * @prop {(...args: unknown[]) => void} handler
*/

import { ObjectID } from 'bson';

export default {
  install: (app, { eventBus }) => {
    const notify = new NotificationService(eventBus);
    app.prototype.$notify = notify;
  },
};

export const CONSTANTS = {
  NOTIFY: 'notify',
};

class NotificationService {
  constructor (eventBus) {
    this.eventBus = eventBus;
  };

  /** @param {NotificationOptions} options */
  success (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('success', options));
  }

  /** @param {NotificationOptions} options */
  error (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('error', options));
  }

  /** @param {NotificationOptions} options */
  info (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('info', options));
  }

  /** @param {NotificationOptions} options */
  warning (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('warning', options));
  }

  /**
    * @param {string} eventName
    * @param {Notification} notification
  */
  notify (eventName, notification) {
    this.eventBus.$emit(eventName, notification);
  }
};

class Notification {
  /**
    * @param {'success' | 'error' | 'info' | 'warning'} type
    * @param {NotificationOptions} options
  */
  constructor (type, options) {
    this._id = new ObjectID().toHexString();
    this.type = type;
    if (typeof options === 'string') {
      this.text = options;
    } else {
      this.title = options.title || '';
      this.text = options.text || '';
      this.hasClosedButton = options.hasClosedButton || false;
      this.withoutDuration = options.withoutDuration || false;
      this.actionButton = options.actionButton || null;
    };
  }
};
