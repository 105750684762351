<template>
  <div class="workspace-labels">
    <div class="flex-row flex-gap-4">
      <default-button
        v-if="selectedFilter!== 'all'"
        form="square"
        size="m"
        title="Add label"
        class="workspace-labels__create-label"
        color="primary"
        icon-name="bx-plus"
        @action="show.create = true"
      />
      <default-button
        v-for="button in filterButtons"
        :key="button.id"
        form="ellipse"
        size="s"
        :title="button.name"
        :color="button.id === selectedFilter ? 'primary' : 'transparent'"
        @action="changeFilter(button.id)"
      />
    </div>
    <ul
      ref="vldParent"
      class="vld-parent workspace-labels__list"
    >
      <li
        v-for="label in labelsList"
        :key="label._id"
        class="flex-row-between workspace-labels__item"
      >
        <div class="flex-row-between flex-gap-8">
          <i class="bx bxs-purchase-tag workspace-labels__name-icon" />
          <h3 class="text_s workspace-labels__name-text">
            {{ label.name }}
          </h3>
        </div>
        <div class="flex-row-between flex-gap-8">
          <p class="text_xs workspace-labels__type">
            {{ label.type }}
          </p>
          <default-tooltip
            :items="updateLabel"
            data-closable="updateLabel"
            :show.sync="show.update"
            class="text_xs workspace-labels__tooltip"
            :is-fulled="true"
            list-position="right"
            :has-triangle="false"
            width="150"
            max-height="200"
            @choose="chooseLabel(label._id)"
          >
            <template #default>
              <default-button
                form="circle"
                icon-name="bx-dots-vertical-rounded"
                size="s"
                color="neutral"
              />
            </template>
          </default-tooltip>
        </div>
      </li>
    </ul>
    <update-label-modal
      :show.sync="show.create"
      @update-label="createLabel"
    />
    <delete-confirmation-modal
      text="Confirm deleting label"
      button-text="Delete label"
      :show.sync="show.delete"
      name="deleteLabel"
      @confirm="deleteLabel"
    />
  </div>
</template>

<script>
  import { createLabel, deleteLabel, getLabels } from '@/api/labelsMethods';

  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';
  import UpdateLabelModal from '@/modals/UpdateLabelModal.vue';
  import { loaderMixin } from '@/mixins/loaderMixin';

  export default {
    name: 'WorkspaceLabels',
    components: {
      DefaultButton,
      DefaultTooltip,
      UpdateLabelModal,
      DeleteConfirmationModal,
    },
    mixins: [loaderMixin],
    data () {
      return {
        labelId: '',
        allLabels: [],

        selectedFilter: 'all',

        show: {
          update: false,
          create: false,
          delete: false,
        },

        filterButtons: [
          { name: 'All', id: 'all' },
          { name: 'Inbox', id: 'profile' },
          { name: 'Templates', id: 'template' },
          { name: 'Customers (Admin)', id: 'space' },
        ],
        updateLabel: [{ name: 'Delete', id: 'delete', iconName: 'bx-trash' }],
      };
    },

    computed: {
      labelsList () {
        if (this.selectedFilter === 'all') return this.allLabels;
        return this.allLabels.filter(
          (label) => label.type === this.selectedFilter
        );
      },
    },
    async mounted () {
      this.loaded = false;
      await this.getLabels('profile');
      await this.getLabels('template');
      await this.getLabels('space');
      this.loaded = true;
    },
    methods: {
      changeFilter (choice) {
        this.selectedFilter = choice;
      },

      chooseLabel (labelId) {
        this.labelId = labelId;
        this.show.delete = true;
      },

      async getLabels (type) {
        try {
          const labels = await getLabels(type);
          this.allLabels = [...this.allLabels, ...labels];
        } catch (e) {
          this.$noty.error(e.message);
        }
      },

      async createLabel (label) {
        try {
          const newLabel = await createLabel(
            label.name,
            label.color,
            this.selectedFilter
          );
          this.allLabels.push(newLabel);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },

      async deleteLabel () {
        try {
          await deleteLabel(this.labelId);
          this.allLabels = this.allLabels.filter(
            (label) => label._id !== this.labelId
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>

<style lang="scss">
.workspace-labels {
  &__create-label {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__list {
    margin-top: 8px;
    display: grid;
    gap: 4px;
  }

  &__item {
    padding: 10px 12px;
  }

  &__name-icon {
    color: var(--primary-cl);
  }

  &__name-text {
    font-weight: 400;
  }

  &__type {
    text-transform: capitalize;
  }

  &__tooltip {
    position: relative;
  }
}
</style>
